
/*INPUT START*/

.DefaultInputContainer {
    height: 40px;
    width: 100%;
    /*max-width: 500px;*/
    position: relative;
}

.DefaultInput {
    transition: .2s;
    /*font-family: var(--font-regular);*/
    font-size: 15px;
    width: 100%;
    height: 100%;
    border: 1px solid var(--input-border-color);
    background: var(--text-white);
    padding: 0 15px;
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
}

.DefaultInput::placeholder {
    color: var(--input-placeholder-color)
}

.DefaultInputError {
    color: var(--text-error);
    border: 1px solid #ff9b9b;
    animation: bounce 0.5s ease;
}

.DefaultInputError::placeholder {
    color: var(--text-error);
}

.DefaultInputContainerErrorText {
    position: absolute;
    bottom: -15px;
    left: 3px;
    color: var(--text-error);
    font-size: 11px;
    animation: show_error .2s;
}

/*INPUT END*/



/*BUTTON START*/


.DefaultButton {
    width: fit-content;
    border-radius: 12px;
    background: var(--primary-color);
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0px 12px;
    box-sizing: border-box;
}

.DefaultButton div {
    /*font-size: 14px;*/
    color: var(--text-white);
    font-weight: 700;
    font-size: 13px;
}

.DefaultButton:hover {
    /*background: var(--primary-color-hover);*/
}

.DefaultButtonBorder {
    background: transparent;
    border: 1px solid var(--input-border-color)
}

.DefaultButtonBorder div {
    color: var(--text-twelth)
}

.DefaultButtonBorder:hover {
    /*background: transparent;*/
    /*border: 1px solid var(--text-second);*/
    /*color: var(--text-second);*/
}

.DefaultButtonBorder:hover div {
    /*color: var(--text-second);*/
}

/*BUTTON END*/



/*MODAL START*/
.layout{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ModalContainer{
    padding: 30px;
    box-sizing: border-box;
}

.Modal {
    background: #FFFFFF;
    border-radius: 12px;
}
.ModalButtons{
    display: flex;
    justify-content: end;
}
/*MODAL END*/

